import * as React from "react"
import { graphql } from "gatsby"

import "./about/contacts/contacts.scss"
import Layout from "../components/Layout"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import Title from "../components/Title"
import ContactsBlock from "../components/ContactsBlock"
import { Map, Placemark, YMaps } from "react-yandex-maps"
import SocialNetworks from "../components/SocialNetworks"
import HelpChoosing from "../components/offers/HelpChoosing"
import { AnimatePresence } from "framer-motion"
import FormPopup from "../components/popups/FormPopup"
import VideoPopup from "../components/popups/VideoPopup"
import { useState } from "react"
import { STRAPI_API } from "../consts/api"
import Img from "react-cool-img"

import ImgInfo from "../../static/media/info.jpg"
import {SEO} from "../components/seo";

const Contacts = ({ data, location }) => {
  const { strapiContactsPage, strapiMainPhone } = data
  const [formPopup, setFormPopup] = useState(false)

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  return (
    <div>
      <SEO title={strapiContactsPage?.titleDesc?.pageTitle} description={strapiContactsPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="contactsPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <div className="contacts">
            <Title value={[["dark", "Контакты "]]} />

            <div className="contacts__block m60_40">
              {
                // PHONE
                strapiMainPhone?.phone && (
                  <div className="contacts__cont contacts__cont--view-phone">
                    <div className="contacts__box contacts__box--view-phone contacts__box--phone">
                      <div className="contacts-name">
                        <a href={`Tel:${strapiMainPhone.phone}`}>
                          {strapiMainPhone.title}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              }

              <div className="contacts__cont contacts__cont--column">
                <ContactsBlock
                  classAdd={"m60_40 contacts__box contacts__box--location"}
                />

                {
                  // WORK TIME
                  strapiContactsPage?.workTime && (
                    <div className="contacts__box contacts__box--clock">
                      <div className="contacts-name">
                        <span>{strapiContactsPage.workTime}</span>
                      </div>
                    </div>
                  )
                }
              </div>

              {
                // VIDEO BLOCK
                strapiContactsPage?.videoRoad?.video?.url && (
                  <div className="contacts__item contacts__item--info">
                    <div className="contacts__item-bl">
                      <div className="contacts__item-img">
                        <Img src={ImgInfo} alt="img" />
                      </div>
                      <span className="contacts__item-name">
                        Как добраться от метро?
                      </span>
                      <button
                        className="button-play"
                        onClick={() => {
                          setVideoSrc(
                            STRAPI_API + strapiContactsPage.videoRoad.video.url
                          )
                          setPopUpVideo(true)
                        }}
                      >
                        <span />
                      </button>
                    </div>
                  </div>
                )
              }

              <div className="contacts__item contacts__item--map">
                <div className="contacts__item-bl">
                  <YMaps className="map">
                    <div className="map__info">
                      <span>Адрес на карте</span>
                    </div>
                    <Map
                      className="map__cont"
                      defaultState={{
                        center: [55.759828, 37.663088],
                        zoom: 14,
                      }}
                    >
                      <Placemark geometry={[55.759828, 37.663088]} />
                    </Map>
                  </YMaps>
                </div>
              </div>

              <div className="contacts__cont">
                {
                  // MAIL
                  strapiMainPhone?.mail && (
                    <div className="contacts__box contacts__box--email">
                      <div className="contacts-name">
                        <span>{strapiMainPhone.mail}</span>
                      </div>
                    </div>
                  )
                }

                {
                  // PHONE
                  strapiMainPhone?.phone && (
                    <div className="contacts__box contacts__box--phone">
                      <div className="contacts-name">
                        <a href={`Tel:${strapiMainPhone.phone}`}>
                          {strapiMainPhone.title}
                        </a>
                      </div>
                    </div>
                  )
                }

                {
                  // SOCIALS
                  strapiMainPhone?.socialLinks && (
                    <div className="contacts__box contacts__box--networks">
                      <SocialNetworks data={strapiMainPhone.socialLinks} />

                      <div className="contacts-name">
                        <span>Присоединяйтесь к нам :)</span>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          {
            // OFFER WITH FORM - CHOOSING HELP
            strapiMainPhone?.offer && (
              <div className="helpChoosing m140">
                <HelpChoosing data={strapiMainPhone.offer} />
              </div>
            )
          }
        </DoubleBlock>
      </Layout>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}

        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiMainPhone {
      phone
      title
      mail
      socialLinks {
        socials
        link
      }
    }
    strapiContactsPage {
      workTime
      videoRoad {
        video {
          url
        }
      }
      offer {
        title
        subtitle
        button
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`



export default Contacts
